import { ToggleBarProps } from '../types';
import { useBar } from './hooks';
import { BarContainer, Button, ButtonContainer, ButtonHighLight, ButtonLabel } from './styles';

export const Bar = <T extends React.ReactNode, K extends keyof T>(props: ToggleBarProps<T, K>) => {
  const {
    buttonHighlightProps,
    className,
    dataTest,
    hasValue,
    height,
    onChange,
    padding,
    options,
    selectedIndex,
    setClickedIndex,
  } = useBar(props);

  const barContents = options.map((option, index) => (
    <Button
      key={`${option.value}-${index}`}
      height={height}
      onClick={(e) => onChange(option.value, e)}
      onMouseDown={() => setClickedIndex(index)}
      data-cy={option.dataTest}>
      <ButtonLabel isSelected={hasValue && selectedIndex === index}>{option.label ?? option.value}</ButtonLabel>
    </Button>
  ));

  return (
    <div className={className} data-cy={dataTest}>
      <BarContainer padding={padding}>
        <ButtonContainer padding={padding}>{barContents}</ButtonContainer>
        <ButtonHighLight {...buttonHighlightProps} />
      </BarContainer>
    </div>
  );
};
