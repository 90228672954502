import React from 'react';
import styled from 'styled-components';
import { Font } from 'web-common/src/shared/styles';

const Text = styled.span`
  color: inherit;
  display: inline-block;
  font-family: ${Font.Family.SANS};
  line-height: inherit;
  transform: translateY(0.075em);
`;

interface IVerticallyCenteredTextProps {
  /** The text to vertically center. Additionally you can pass this as `children` prop. */
  text?: string;
  className?: string;
}

/**
 * Wrapper Component for vertically centering text. Due to our font `Gilroy` having a weird baseline,
 * text is not perfectly centered and usually appears higher when aligning vertically. Until we fix
 * the font file, this component can be used to align text vertically.
 *
 * Note 1: Mainly use when trying to center text with an icon in a flex container (display: flex; align-items: center;).
 *
 * Note 2: May not fit all cases.
 *
 * Note 3: Due to the nature of the font, it may not align well if text does not contain a capital letter
 */
export const VerticallyCenteredText: React.FC<React.PropsWithChildren<IVerticallyCenteredTextProps>> = (props) => {
  const { children, text, ...otherProps } = props;
  return (
    <Text {...otherProps}>
      {text}
      {children}
    </Text>
  );
};
